<template>
  <div style="">
    <div v-if='codeShow == 1'>
      <div class="printBtn">
        <div class="cardBox" style="margin: 35px auto; display: flex; justify-content: space-evenly;">
          <el-button type="success" @click="downloadAll">下载全部图片</el-button>
          <el-button type="primary" @click="printAll">全部打印</el-button>
        </div>
      </div>
      <div v-for="(item,index) in remarkList" :key="item" class="cardBox">
        <div :id="`content${index}`">
          <div class="cardBoxHead">
            <h3>数字化电梯物联网安全监管系统 ({{item}})</h3>
          </div>
          <div class="cardBoxContant">
            <div class="boxContant">
              <el-row style="padding: 35px 0 22px">
                <el-col :span="12" class="boxLeft">
                  <div class="line">
                    <h3>使用单位：</h3>
                    <span class="titel">{{dioData.propertyUnitName}}</span>
                  </div>
                  <div class="line">
                    <h3>维保单位： </h3>
                    <span class="titel">{{dioData.maintainUnitName}}</span>
                  </div>
                  <div class="line">
                    <h3>保险单位： </h3>
                    <span class="titel">{{dioData.insuranceUnitName ? dioData.insuranceUnitName : '-'}}</span>
                  </div>
                  <div class="line">
                    <h3>救援识别码： </h3>
                    <span class="titel">{{dioData.rescueCode}}</span>
                  </div>
                  <div class="line">
                    <h3>维保单位电话： </h3>
                    <span class="titel">{{dioData.maintainUnitPhone}}</span>
                  </div>
                  <div class="line">
                    <h3>物业单位电话： </h3>
                    <span class="titel">{{dioData.propertyUnitPhone}}</span>
                  </div>
                  <div class="line">
                    <h3>维保人员： </h3>
                    <span class="titel">{{dioData.maintainUserNames && dioData.maintainUserNames.split(';')[0]}}</span>
                  </div>
                </el-col>
                <el-col :span="12" style="text-align: center;position: relative;">
                  <img :src="require('@/assets/qrcode.png')" style="width: 90%;">
                  <Qrcode
                    :url="`https://${url}/scancode/new_file.html?id=${dioData.id}&type=${index === 0 ? '' : (index == 1?'jiaoding' :(index == 2?'jiaodi' : index == 3?'jifang' :'qita'))}`"
                    style="position: absolute; right: 34px; top: 27.4%;" :size="180"></Qrcode>
                  <h3 class="tipsTitle" style="width: 100%;">打开微信使用扫一扫<br>获取更多电梯信息</h3>
                  <h3 style="color: black;margin-top: -10px; width: 100%;"
                    v-show="dioData.deviceSosSwitch&&JSON.parse(dioData.deviceSosSwitch).wechatCodeSos == 1">进行<span
                      style="color: #D9001B;">一键呼救</span></h3>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="cardBoxBottom">
          </div>
        </div>
      </div>
    </div>
    <div v-if='codeShow == 2'>
      <div class="printBtn">
        <div class="cardBox" style="margin: 35px auto; display: flex; justify-content: space-evenly;">
          <el-button type="success" @click="downloadAllWenshan">下载全部图片</el-button>
          <el-button type="primary" @click="printAll">全部打印</el-button>
        </div>
      </div>
      <div style="margin: 0 auto 100px; width: 1080px; position: relative;" id="content3">
        <!-- <div style=" height: 720px;"> -->
        <img src="../../assets/wenshang/wenshang.png" style="width: 1080px;height: 716px;position: relative;">
        <div class="wordInfo">{{dioData.rescueCode}}</div>
        <div class="codeOne">
          <Qrcode :url="`https://${url}/scancode/new_file.html?id=${dioData.id}`"
            style="position: absolute;  right: calc(100% - 980px); top:-375px " size="230"></Qrcode>
        </div>
        <!-- </div> -->
      </div>
      <template v-if="dioData.varieties != 'ESCALATORS_AND_AUTOMATED_WALKWAYS'">
        <div v-for="(item,index) in 3" :key="item" style="width: 720px; margin: 0 auto 100px; position: relative; background: #fff;">
          <!-- </div> -->
          <div :id="`content${index}`">
            <div style="position: relative;width: 720px;height: 720px; margin: 30px auto;">
              <img :src="require(`@/assets/wenshang/wenshang${index + 1}.png`)"
                style="width: 720px;height: 720; margin: 0 auto">
              <Qrcode
                :url="`https://${url}/scancode/new_file.html?id=${dioData.id}&type=${index === 0 ? 'jiaoding' : (index == 1?'dikeng' : 'jifang')}`"
                style="position: absolute; right: calc(50% - 175px); top: calc(50% - 150px); z-index: 99;" size="350">
              </Qrcode>
              <div class="wordInfoOne">{{dioData.rescueCode}}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="(item,index) in 2" :key="item" style="width: 720px; margin: 0 auto 100px; position: relative; background: #fff;">
          <!-- </div> -->
          <div :id="`content${index}`">
            <div style="position: relative;width: 720px;height: 720px; margin: 30px auto;">
              <img :src="require(`@/assets/wenshang/wenshang${index + 4}.png`)"
                style="width: 720px;height: 720; margin: 0 auto">
              <Qrcode
                :url="`https://${url}/scancode/new_file.html?id=${dioData.id}&type=${index === 0 ? 'shangjicang' : 'xiajicang' }`"
                style="position: absolute; right: calc(50% - 175px); top: calc(50% - 150px); z-index: 99;" size="350">
              </Qrcode>
              <div class="wordInfoOne">{{dioData.rescueCode}}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Qrcode from '@/components/qrcode';
	import html2canvas from 'html2canvas'
  export default {
    components: {
      Qrcode
    },
    data() {
      return {
        url: window.location.hostname == 'localhost' ? 'zxtlw.zyzntech.com' : window.location.hostname,
        dioData: {},
        codes: '',
        codeShow: 0,
        remarkList: ['轿厢', '轿顶', '轿底', '机房', '其它'],
        remarkListWenshan: [ '轿顶', '底坑', '机房'],
      }
    },
    methods: {
      printAll () {
        window.print()
      },
      downloadAllWenshan () {
        for (let index = 0; index < 4; index++) {
          if (index == 3) {
            this.downImage(index, `${this.dioData.rescueCode}轿厢`)
          }
          if (index < 3) {
            this.downImage(index, `${this.dioData.rescueCode}${this.remarkListWenshan[index]}`)
          }
        }
      },
      downloadAll () {
        // this.remarkList.map(item)
        for (let index = 0; index < this.remarkList.length; index++) {
          this.downImage(index, this.remarkList[index])
        }
      },
      downImage (index, name) {
        let content = document.getElementById(`content${index}`)
        let scrollHeight = content.offsetHeight
        let scrollWidth = content.offsetWidth
        html2canvas(content, {
          scale: 1,
          useCORS: true, //开启跨域配置，但和allowTaint不能共存
          width: scrollWidth,
          height: scrollHeight,
          windowWidth: scrollWidth,
          windowHeight: scrollHeight,
          x: 0,
          y: window.pageYOffset
        }).then((canvas) => {
          let dataURL = canvas.toDataURL("image/png");
          let filename = `${name}.png`;
          let link = document.createElement("a");
          link.href = dataURL;
          link.setAttribute("download", filename);
          link.style.display = "none"; //a标签隐藏
          document.body.appendChild(link);
          link.click();
          // if (type == 2) {
          //   let file_url = dataURLtoFile(dataURL, filename,
          //     "image/png"); //将文件转换成file的格式，就可以使用file_url传递给服务端了
          //   let Files = [{
          //     name: file_url.name,
          //     percentage: 0,
          //     raw: file_url,
          //     size: file_url.size,
          //     status: "ready",
          //   }]
          //   emit('updAnnou', Files)
          // }
          // cancl()
        })
      }
    },
    beforeCreate() {
      document.querySelector('html').setAttribute('style', 'background-color: #fff!important')
      document.querySelector('#app').setAttribute('style', 'background-color: #fff!important')
    },
    created() {
      let url1 = '/api/ele/web/elevatorInfo/list'
      this.$http
        .post(url1, {
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.dioData = res.data.data.records[0]
            this.codes = res.data.data.records[0].areaCodes
            this.codeShow = JSON.parse(this.codes).find(item => item == 532600000000) && JSON.parse(this.codes).find(
              item => item == 532600000000) != undefined ? 2 : 1
            // setTimeout(() => {
            //   window.print()
            // }, 2000)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
  }
</script>

<style lang="scss" scoped>
  .codeOne {
    text-align: center;
    position: relative;
    // top: -465px;
    // margin-left: -132px;
  }

  .codeTwo {
    text-align: center;
    position: relative;
    // top: -535px;
    // margin-left: -665px;
  }

  .wordInfo {
    // position: relative;
    // top: -558px;
    // right: -430px;
    position: absolute;
    top: calc(27% - 37.5px);
    left: calc(24% + 150px);
    width: 300px;
    // margin-top: -560px;
    // margin-left: 431px;
    font-size: 75px;
    font-family: fangsong;
    font-weight: 700;
    color: #FFE500;
  }

  .wordInfoOne {
    position: absolute;
    top: 60px;
    // left: calc(50% - 130px);
    width: 720px;
    text-align: center;
    // margin-top: -680px;
    // margin-left: 231px;
    font-size: 75px;
    font-family: fangsong;
    font-weight: 700;
    color: #FFE500;
  }

  .wordInfoTwo {
    position: absolute;
    top: 8%;
    left: 240px;
    // margin-top: -680px;
    // margin-left: 231px;
    font-size: 75px;
    font-family: fangsong;
    font-weight: 700;
    color: #FFE500;
    z-index: 999;
  }

  .wordInfoThree {}

  .cardBox {
    margin: 35px auto 80px;
    background: #fff;
    width: 1080px;

    .cardBoxHead {
      background: #2F673C;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 35px;
        letter-spacing: 7px;
        margin: 25px 0px;
      }
    }

    .cardBoxContant {
      background: #D9D385;

      .boxContant {
        width: 90%;
        margin: 0 auto;

        .boxLeft {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 480px;

          .line {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .titel {
            padding-bottom: 10px;
            border-bottom: 2px solid #000;
            letter-spacing: 3px;
            padding-left: 10px;
            width: 68%;
            display: inline-block;
            font-size: 17px;
            font-weight: bolder;
          }
        }

        .tipsTitle {
          font-weight: bolder;
          line-height: 1.8em;
          font-size: 22px;
        }
      }
    }

    .cardBoxBottom {
      height: 20px;
      background: #2F673C;
    }
  }

  @media print {
    .printBtn{
      display:  none;
    }
    // .wordInfoTwo {
    //   position: absolute;
    //   top: 42%;
    //   left: 240px;
    //   // margin-top: -680px;
    //   // margin-left: 231px;
    //   font-size: 75px;
    //   font-family: fangsong;
    //   font-weight: 700;
    //   color: #FFE500;
    //   z-index: 999;
    // }

    // body * {
    // visibility: hidden;
    // }
    // #app * {
    // visibility: visible;
    // }
    // #app {
    // background: white;
    // }
  }
</style>
